import React, { useState, useEffect } from 'react';
import Logo from "../Assets/rebite logo large.png";
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../config/firebaseSetup';

export const Vendornavbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [perms, setPerms] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
      setPerms(user ? user.vendorVerify : false);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/');
  };

  const handleLogoClick = () => {
    navigate(isLoggedIn ? "/dashboard" : "/");
  };

  return (
    <div className="flex justify-between items-center px-5 bg-white fixed w-full top-0 left-0 z-50">
      <div className="transition-all duration-500 ease-out w-1/4 min-w-[200px] m-2">
        <div onClick={handleLogoClick} className="cursor-pointer">
          <img src={Logo} alt="Rebite Logo" className="max-h-12 w-[25%]" />
        </div>
      </div>

      <button
        className={`lg:hidden z-50 p-2 ${isNavExpanded ? 'fixed right-4' : ''}`}
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        <div className={`w-6 h-0.5 bg-black mb-1.5 transition-all duration-300 ${
          isNavExpanded ? 'transform rotate-45 translate-y-2' : ''
        }`}></div>
        <div className={`w-6 h-0.5 bg-black mb-1.5 transition-opacity duration-300 ${
          isNavExpanded ? 'opacity-0' : ''
        }`}></div>
        <div className={`w-6 h-0.5 bg-black transition-all duration-300 ${
          isNavExpanded ? 'transform -rotate-45 -translate-y-2' : ''
        }`}></div>
      </button>

      <nav className={`fixed top-0 right-0 h-screen bg-white w-64 transform transition-transform duration-300 ease-in-out lg:relative lg:h-auto lg:w-auto lg:bg-transparent lg:transform-none ${
        isNavExpanded ? 'translate-x-0' : 'translate-x-full lg:translate-x-0'
      } shadow-lg lg:shadow-none`}>
        <ul className="flex flex-col lg:flex-row items-start lg:items-center list-none m-0 p-6 lg:p-0">
          <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
            <Link 
              to="/orders" 
              className="block text-black no-underline px-2.5 py-1.5 rounded-full transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)]"
              onClick={() => setIsNavExpanded(false)}
            >
              Orders
            </Link>
          </li>
          <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
            <Link 
              to="/new-menu-item" 
              className="block text-black no-underline px-2.5 py-1.5 rounded-full transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)]"
              onClick={() => setIsNavExpanded(false)}
            >
              Menus
            </Link>
          </li>
          <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
            <Link 
              to="/Flashsales" 
              className="block text-black no-underline px-2.5 py-1.5 rounded-full transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)]"
              onClick={() => setIsNavExpanded(false)}
            >
              Flash Sale
            </Link>
          </li>
          {isLoggedIn ? (
            <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
              <button 
                onClick={() => {
                  handleLogout();
                  setIsNavExpanded(false);
                }}
                className="block text-black no-underline px-2.5 py-1.5 rounded-full transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)] w-full text-left bg-transparent cursor-pointer text-base"
              >
                Logout
              </button>
            </li>
          ) : (
            <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
              <Link 
                to="/login"
                className="block text-black no-underline px-2.5 py-1.5 rounded-full transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)]"
                onClick={() => setIsNavExpanded(false)}
              >
                Login
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Vendornavbar;