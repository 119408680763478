import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Vendornavbar } from '../Vendornav';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog.tsx";
import { Button } from "../ui/button.tsx";
import { Badge } from "../ui/badge.tsx";
import { Separator } from "../ui/separator.tsx";
import { Clock, ChefHat, ShoppingCart, TrendingUp, ArrowRight, AlertTriangle } from "lucide-react";
import { fetchMenuItems } from '../../API/menuAPI';
import { fetchVendorFlashSales } from '../../API/flashsaleAPI';
import { getVendorOrders } from '../../API/vendorAPI';
import { auth } from '../../config/firebaseSetup';

export const Dashboard = () => {
  const [menuItems, setMenuItems] = useState({});
  const [flashSales, setFlashSales] = useState([]);
  const [orders, setOrders] = useState({ completedOrders: [], incompletedOrders: [] });
  const [errors, setErrors] = useState({ menu: false, sales: false, orders: false });
  const navigate = useNavigate();

  useEffect(() => {
          const unsubscribe = auth.onAuthStateChanged(user => {
              if (user) {
                  console.log("user is logged in");
              } else {
                  navigate('/login');
              }
          });
          return () => unsubscribe();
      }, [navigate]);

  useEffect(() => {

    

    const fetchData = async () => {
      try {
        const menuData = await fetchMenuItems();
        setMenuItems(menuData || {});
      } catch (error) {
        console.error('Menu fetch error:', error);
        setErrors(prev => ({ ...prev, menu: true }));
        setMenuItems({});
      }

      try {
        const salesData = await fetchVendorFlashSales();
        setFlashSales(salesData || []);
      } catch (error) {
        console.error('Flash sales fetch error:', error);
        setErrors(prev => ({ ...prev, sales: true }));
        setFlashSales([]);
      }

      try {
        const ordersData = await getVendorOrders();
        setOrders(ordersData || { completedOrders: [], incompletedOrders: [] });
      } catch (error) {
        console.error('Orders fetch error:', error);
        setErrors(prev => ({ ...prev, orders: true }));
        setOrders({ completedOrders: [], incompletedOrders: [] });
      }
    };
    fetchData();
  }, []);

  const totalMenuItems = Object.values(menuItems).flat().length;
  const activeFlashSales = flashSales.length;
  const pendingOrders = orders?.incompletedOrders?.length || 0;

  const ErrorBadge = ({ show }) => show ? (
    <Badge variant="destructive" className="bg-red-100 text-red-800">
      <AlertTriangle className="h-3 w-3 mr-1" /> Error Loading
    </Badge>
  ) : null;

  return (
    <div className="min-h-screen bg-gray-50">
      <Vendornavbar />
      
      <div className="container mx-auto px-4 pt-24">
        <h1 className="text-4xl font-bold mb-8 bg-gradient-to-r from-orange-500 to-amber-500 bg-clip-text text-transparent">
          Welcome Back
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {/* Menu Overview Card */}
          <Dialog>
            <DialogTrigger asChild>
              <Card className="hover:shadow-lg transition-shadow cursor-pointer">
                <CardContent className="pt-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-gray-500">Total Menu Items</p>
                      <p className="text-3xl font-bold">{errors.menu ? '-' : totalMenuItems}</p>
                    </div>
                    <ChefHat className="h-8 w-8 text-orange-500" />
                  </div>
                  <ErrorBadge show={errors.menu} />
                </CardContent>
              </Card>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Menu Overview</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                {errors.menu ? (
                  <p className="text-red-500">Unable to load menu items</p>
                ) : (
                  Object.entries(menuItems).map(([category, items]) => (
                    items.length > 0 && (
                      <div key={category}>
                        <h3 className="font-medium mb-2">{category}</h3>
                        <div className="grid grid-cols-2 gap-4">
                          {items.slice(0, 4).map((item, idx) => (
                            <div key={idx} className="flex items-center space-x-2">
                              <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center">
                                ${item.price}
                              </div>
                              <span className="text-sm truncate">{item.name}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  ))
                )}
                <Button 
                  onClick={() => navigate('/menu')}
                  className="w-full bg-gradient-to-r from-orange-500 to-amber-500"
                >
                  View Full Menu
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          {/* Flash Sales Card */}
          <Dialog>
            <DialogTrigger asChild>
              <Card className="hover:shadow-lg transition-shadow cursor-pointer">
                <CardContent className="pt-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-gray-500">Active Flash Sales</p>
                      <p className="text-3xl font-bold">{errors.sales ? '-' : activeFlashSales}</p>
                    </div>
                    <TrendingUp className="h-8 w-8 text-orange-500" />
                  </div>
                  <ErrorBadge show={errors.sales} />
                </CardContent>
              </Card>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Active Flash Sales</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                {errors.sales ? (
                  <p className="text-red-500">Unable to load flash sales</p>
                ) : (
                  flashSales.map((sale, idx) => (
                    <Card key={idx}>
                      <CardContent className="p-4">
                        <div className="flex justify-between items-center">
                          <div>
                            <h3 className="font-medium">{sale.productName}</h3>
                            <p className="text-sm text-gray-500">${sale.discountedPrice}</p>
                          </div>
                          <Badge className="bg-orange-100 text-orange-800">
                            {Math.floor(sale.time / 60)}h {sale.time % 60}m
                          </Badge>
                        </div>
                      </CardContent>
                    </Card>
                  ))
                )}
                <Button 
                  onClick={() => navigate('/flashsales')}
                  className="w-full bg-gradient-to-r from-orange-500 to-amber-500"
                >
                  Manage Flash Sales
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          {/* Orders Card */}
          <Dialog>
            <DialogTrigger asChild>
              <Card className="hover:shadow-lg transition-shadow cursor-pointer">
                <CardContent className="pt-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-gray-500">Pending Orders</p>
                      <p className="text-3xl font-bold">{errors.orders ? '-' : pendingOrders}</p>
                    </div>
                    <ShoppingCart className="h-8 w-8 text-orange-500" />
                  </div>
                  <ErrorBadge show={errors.orders} />
                </CardContent>
              </Card>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Recent Orders</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                {errors.orders ? (
                  <p className="text-red-500">Unable to load orders</p>
                ) : (
                  <>
                    {orders.incompletedOrders.slice(0, 3).map((order, idx) => (
                      <Card key={idx}>
                        <CardContent className="p-4">
                          <div className="flex justify-between items-center">
                            <div>
                              <h3 className="font-medium">{order.usersName}</h3>
                              <p className="text-sm text-gray-500">
                                {order.foodItem.join(', ')}
                              </p>
                            </div>
                            <Badge variant="outline">Pending</Badge>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                    <Separator />
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-gray-500">
                        Completed Today: {orders.completedOrders.length}
                      </p>
                      <Button 
                        onClick={() => navigate('/orders')}
                        variant="ghost"
                        className="text-orange-500"
                      >
                        View All <ArrowRight className="ml-2 h-4 w-4" />
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>

        {/* Activity Timeline */}
        <Card>
          <CardHeader>
            <CardTitle>Recent Activity</CardTitle>
            <CardDescription>Your latest business updates</CardDescription>
          </CardHeader>
          <CardContent>
            {errors.orders && errors.sales ? (
              <p className="text-red-500">Unable to load activity timeline</p>
            ) : (
              <div className="space-y-6">
                {[...(orders.incompletedOrders || []), ...flashSales].slice(0, 5).map((item, idx) => (
                  <div key={idx} className="flex items-center space-x-4">
                    <div className="flex-none">
                      {'foodItem' in item ? (
                        <ShoppingCart className="h-6 w-6 text-orange-500" />
                      ) : (
                        <TrendingUp className="h-6 w-6 text-orange-500" />
                      )}
                    </div>
                    <div className="flex-1">
                      <p className="font-medium">
                        {'foodItem' in item ? (
                          `New order from ${item.usersName}`
                        ) : (
                          `Flash sale on ${item.productName}`
                        )}
                      </p>
                      <p className="text-sm text-gray-500">
                        {'foodItem' in item ? (
                          `${item.foodItem.join(', ')}`
                        ) : (
                          `$${item.discountedPrice}`
                        )}
                      </p>
                    </div>
                    <Badge variant="outline" className="flex-none">
                      <Clock className="mr-1 h-3 w-3" />
                      Just now
                    </Badge>
                  </div>
                ))}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};