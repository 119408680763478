import React, { useState, useEffect } from 'react';
import Logo from "../Assets/rebite logo large.png";
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../config/firebaseSetup';
import { FiLogIn } from 'react-icons/fi';

export const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [perms, setPerms] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
      setPerms(user ? user.vendorVerify : false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e.target.closest('.profile-dropdown')) {
        setIsProfileOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  const handleLogout = () => {
    navigate('/');
    auth.signOut();
  };

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleLogoClick = () => {
    navigate(isLoggedIn ? "/vendorportal" : "/");
  };

  return (
    <div className="flex justify-between items-center px-5 bg-white fixed w-full top-0 left-0 z-50">
      <div className="transition-all duration-500 ease-out w-1/4 min-w-[200px] m-2">
        <div onClick={handleLogoClick} className="cursor-pointer">
          <img src={Logo} alt="Rebite Logo" className="max-h-12 w-[25%]" />
        </div>
      </div>

      <button
        className={`lg:hidden z-50 p-2 ${isNavExpanded ? 'fixed right-4' : ''}`}
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        <div className={`w-6 h-0.5 bg-black mb-1.5 transition-all duration-300 ${
          isNavExpanded ? 'transform rotate-45 translate-y-2' : ''
        }`}></div>
        <div className={`w-6 h-0.5 bg-black mb-1.5 transition-opacity duration-300 ${
          isNavExpanded ? 'opacity-0' : ''
        }`}></div>
        <div className={`w-6 h-0.5 bg-black transition-all duration-300 ${
          isNavExpanded ? 'transform -rotate-45 -translate-y-2' : ''
        }`}></div>
      </button>

      <nav className={`fixed top-0 right-0 h-screen bg-white w-64 transform transition-transform duration-300 ease-in-out lg:relative lg:h-auto lg:w-auto lg:bg-transparent lg:transform-none ${
        isNavExpanded ? 'translate-x-0' : 'translate-x-full lg:translate-x-0'
      } shadow-lg lg:shadow-none`}>
        <ul className="flex flex-col lg:flex-row items-start lg:items-center list-none m-0 p-6 lg:p-0">
          <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
            <Link 
              to="/About" 
              className="block text-black no-underline px-2.5 py-1.5 rounded-full transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)]"
              onClick={() => setIsNavExpanded(false)}
            >
              Our Mission
            </Link>
          </li>
          <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
            <Link 
              to="/contact" 
              className="block text-black no-underline px-2.5 py-1.5 rounded-full transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)]"
              onClick={() => setIsNavExpanded(false)}
            >
              Contact
            </Link>
          </li>

          {!isLoggedIn && (
            <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
              <Link 
                to="/login"
                onClick={() => setIsNavExpanded(false)}
              >
                <button
      className={`
        px-4 py-2 rounded-full 
        flex items-center gap-2 
        text-slate-500
        shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.25)]
        
        transition-all

        hover:shadow-[-1px_-1px_5px_rgba(255,_255,_255,_0.6),_1px_1px_5px_rgba(0,_0,_0,_0.3),inset_-2px_-2px_5px_rgba(255,_255,_255,_1),inset_2px_2px_4px_rgba(0,_0,_0,_0.3)]
        hover:text-rebite-orange
    `}
    >
      <FiLogIn />
      <span>Dashboard</span>
    </button>
              </Link>
            </li>
          )}

          {isLoggedIn && (
            <li className="my-2 lg:mx-2.5 w-full lg:w-auto">
              <div className="profile-dropdown relative">
                <button 
                  onClick={toggleProfileDropdown}
                  className="bg-transparent border-none text-black cursor-pointer font-sans px-2.5 py-1.5 rounded-full text-base leading-normal inline-flex items-center transition-all duration-300 hover:bg-black/10 hover:shadow-[0_0_0_2px_rgba(255,255,255,0.5)]"
                >
                  Profile
                </button>
                <div className={`${
                  isProfileOpen ? 'block' : 'hidden'
                } absolute lg:top-[calc(100%+10px)] lg:right-0 right-0 rounded-2xl shadow-lg z-50 min-w-[180px] overflow-hidden`}>
                  <ul className="list-none m-0 py-2">
                    <li>
                      <Link 
                        to="/dashboard" 
                        className="block px-5 py-3 text-black no-underline transition-colors duration-300 hover:bg-[#FF7043] w-full text-left"
                        onClick={() => setIsNavExpanded(false)}
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/settings" 
                        className="block px-5 py-3 text-black no-underline transition-colors duration-300 hover:bg-[#FF7043] w-full text-left"
                        onClick={() => setIsNavExpanded(false)}
                      >
                        Settings
                      </Link>
                    </li>
                    <li>
                      <button 
                        onClick={() => {
                          handleLogout();
                          setIsNavExpanded(false);
                        }}
                        className="block px-5 py-3 text-black no-underline transition-colors duration-300 hover:bg-[#FF7043] w-full text-left border-none bg-transparent cursor-pointer text-base"
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};