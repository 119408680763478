import axios from 'axios'
import config from '../config/config';
const { fdb, auth, storage } = require('../config/firebaseSetup');

export const fetchVendorFlashSales = async () => {
    const url = `${config.apiBaseUrl}/flashsaleAPI/allFlashSales`;
    console.log("is this the correct url to hit: " + url)
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    if (response.ok) {
        console.log("fetch all flash sales worked!");
        const res = await response.json();
        return res
    }
}

export const fetchPastSales = async () => {
    const url = `${config.apiBaseUrl}/flashsaleAPI/getPastSales`;
    console.log("is this the correct url to hit: " + url)
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    if (response.ok) {
        console.log("fetch all past sales worked!");
        const res = await response.json();
        return res
    }
}

export const createFlashSale = async (saleData) => {
    console.log("Are we attempting the create call")
    console.log(saleData)
    const url = `https://engine.getrebite.com/flashsales/create`;
    console.log("check this create url " + url)
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ flashsaleData: saleData }),
    });
    if (response.ok) {
        console.log("create flash sales worked!");
        const res = await response.json();
        return res
    }
}

export const updateFlashSale = async (saleData, saleId) => {
    const reqBody = {
        body: saleData,
        currentSaleId: saleId
    }
    const url = `${config.apiBaseUrl}/flashsaleAPI/updateFlashSale`;
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'UPDATE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reqBody })
    });
    if (response.ok) {
        console.log("create flash sales worked!");
        const res = await response.json();
        return res
    }
}

export const deletePastSale = async (pastSaleId) => {
    const url = `${config.apiBaseUrl}/flashsaleAPI/deletePastSale`;
    console.log("This is the data we get at the delete endpoint " + pastSaleId)
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ pastSaleId })
    });
    if (response.ok) {
        console.log("got response for this sale id");
        const res = await response.json();
        return res
    }
}

export const fetchSaleById = async (saleId) => {
    const url = `${config.apiBaseUrl}/flashsaleAPI/getFlashSaleById`;
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ saleId })
    });
    if (response.ok) {
        console.log("got response for this sale id");
        const res = await response.json();
        return res
    }
}

export const deleteFlashSale = async (saleId) => {
    const url = `${config.apiBaseUrl}/flashsaleAPI/deleteFlashSale`;
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ saleId })
    });
    if (response.ok) {
        console.log("got response for this sale id");
        const res = await response.json();
        return res
    }
}

export const addToPast = async (saleData, saleId) => {
    console.log("Attempted add to past call")
    const reqBody = {
        saleData: saleData,
        currentSaleId: saleId
    }
    const url = `${config.apiBaseUrl}/flashsaleAPI/addToPast`;
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reqBody })
    });
    if (response.ok) {
        console.log("create flash sales worked!");
        const res = await response.json();
        return res
    }
}