import React, { useState, useEffect } from 'react'; 
import { fdb, auth } from '../../config/firebaseSetup'; 
import { Vendornavbar } from '../../components/Vendornav';
import { collection, addDoc } from "firebase/firestore";  
import {useNavigate, Link} from 'react-router-dom'

export const Menu = () => {
    return (
        <div>Menu</div>
    );
};