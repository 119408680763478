import React, { useState } from 'react'
import { Navbar } from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { doc, getDoc, getDocs, collection, arrayUnion, writeBatch, updateDoc, setDoc } from "firebase/firestore";
import { fdb, auth } from '../config/firebaseSetup'
import emailSymb from '../Assets/emailSymbol.png'
import passwordSymb from '../Assets/passwordSymb.png'
import hiddenPassword from '../Assets/hiddenPassword.png'
import showPassword from '../Assets/showPassword.png'
import { Button } from "./ui/button.tsx";
import { Input } from "./ui/input.tsx";
import { Card, CardContent } from "./ui/card.tsx";
import { Label } from "./ui/label.tsx";
import phonebg from '../Assets/Rebite Phone Backdrop.png'
import { useEffect } from 'react';

export const Login = () => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  //const [ifHiddenPassword, setIfHiddenPassword] = useState({ hiddenPassword });
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [ifVerified, setIfVerified] = useState(false);
  const navigate = useNavigate();

  /*   screenWidth * (x/1440)
    screenHeight * (x/1024) */

  useEffect(() => {
                const subscribe = auth.onAuthStateChanged(user => {
                    if (user) {
                        navigate('/dashboard');
                    } else {
                        console.log("user isn't in");
                    }
                });
                return () => subscribe();
            }, [navigate]);

  const login = e => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        vendorVerified().then(veri => {
          console.log("veri: ", veri)
          if (!veri) {
            console.log(auth.currentUser.uid)
            console.log("justin check this id token")
            console.log(auth.currentUser.getIdToken)
            navigate('/dashboard');
          } else {
            auth.signOut().then(() => navigate('/'));
          }
        }).catch(error => {
          console.error("error: ", error)
          setLoading(false);
        })
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  };

  const vendorVerified = async () => {
    const vendorRef = doc(fdb, "Vendors", auth.currentUser.uid);
    const vendorDoc = await getDoc(vendorRef)
    if (vendorDoc.exists()) {
      const verifyVendor = vendorDoc.data().vendorVerify;
      console.log("Check here")
      console.log(verifyVendor)
      if (verifyVendor == true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };


  const inputStyleEmail = {
    width: (screenHeight <= screenWidth) ? `${screenWidth * (340 / 1440)}px` : `${screenWidth * (340 / 393)}px`,
    height: `${screenHeight * (40 / 1024)}px`,
    paddingLeft: (screenHeight <= screenWidth) ? `${screenWidth * (30 / 1440)}px` : `${screenWidth * (30 / 393)}px`,
    backgroundImage: `url(${emailSymb})`,
    backgroundPosition: `${screenHeight * (10 / 1024)}px center`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${screenHeight * (20 / 1024)}px ${screenHeight * (20 / 1024)}px`,
    borderRadius: '10px',
    backgroundColor: '#F1F1F1'
  };

  const inputStylePassword = {
    width: (screenHeight <= screenWidth) ? `${screenWidth * (340 / 1440)}px` : `${screenWidth * (340 / 393)}px`,
    height: `${screenHeight * (40 / 1024)}px`,
    paddingLeft: (screenHeight <= screenWidth) ? `${screenWidth * (30 / 1440)}px` : `${screenWidth * (30 / 393)}px`,
    backgroundImage: `url(${passwordSymb})`,
    backgroundPosition: `${screenHeight * (10 / 1024)}px center`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${screenHeight * (20 / 1024)}px ${screenHeight * (20 / 1024)}px`,
    borderRadius: '10px',
    backgroundColor: '#F1F1F1'
  };

  return (
    <div>
      <Navbar />
    <div className="flex min-h-svh flex-col items-center justify-center bg-white p-6 md:p-10">
      <div className="w-full max-w-sm md:max-w-3xl">
        <div className="flex flex-col gap-6">
          <Card className="overflow-hidden">
            <CardContent className="grid p-0 md:grid-cols-2">
              <form onSubmit={login} name='login-form' className="p-6 md:p-8">
                <div className="flex flex-col gap-6">
                <div className="flex flex-col items-center text-center">
                <h1 className="text-2xl font-bold">Welcome back</h1>
                <p className="text-balance text-muted-foreground">
                  Login to your Rebite dashboard
                </p>
                </div>
                <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
                />
                </div>
                <div className="grid gap-2">
                  <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>
                  <a
                    href="/PasswordChange"
                    className="ml-auto text-sm underline-offset-2 hover:underline"
                  >
                    Forgot Your Password?
                  </a>
                  </div>
                  <Input
                  type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
                placeholder='Enter your password'/>
                </div>
                <Button type="submit" className="w-full" disabled={loading}>
                {loading ? 'Loggin In...' : 'Login'}
                </Button>
                <div className="text-center text-sm">
                Don&apos;t have an account?{" "}
                <a href="/vendor-reg" className="underline underline-offset-4">
                  Sign Up
                </a>
                </div>
                </div>
              </form>
              <div className="relative hidden bg-white md:block">
            <img
              src={phonebg}
              alt="Rebite app splashpage with multiple phones displaying the app"
              className="absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
            />
          </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
    </div>
  );
}
