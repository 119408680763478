import React, { useState, useEffect } from 'react';
import { Navbar } from './Navbar';
import Logo from '../Assets/rebite logo large.png';
import placeholder from '../Assets/rebite logo large.png';
import efficiency from '../Assets/timeblack.png';
import { Card, CardContent } from './ui/card.tsx';
import { FiClock, FiTrendingUp, FiDollarSign } from 'react-icons/fi';
import { motion, useMotionValue } from "framer-motion";

const cardData = [
  {
    icon: FiTrendingUp,
    title: "Helping Small Businesses",
    description: "Our app is for the local restaurants present in every town that need a place to showcase their affordable food deals. Rebite's easy-to-use dashboard allows for unique marketing and more daily revenue."
  },
  {
    icon: FiDollarSign,
    title: "Promoting Efficiency",
    description: "1/5 of the food produced every day is wasted, contributing to millions of tons of food waste each year. Rebite works to solve this, giving restaurants the option to offer affordable food to price-sensitive customers."
  },
  {
    icon: FiClock,
    title: "Maximizing Convenience",
    description: "We understand that restaurant owners live busy lives attending to customers. That’s why we built the technology to onboard any restaurant menu in seconds, so you can get started immediately."
  }
];

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 10;
const DRAG_BUFFER = 50;
const SPRING_OPTIONS = {
  type: "spring",
  mass: 3,
  stiffness: 400,
  damping: 50,
};

export const AboutUs = () => {
  const [cardIndex, setCardIndex] = useState(0);
  const dragX = useMotionValue(0);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      const x = dragX.get();
      if (x === 0) {
        setCardIndex((pv) => {
          if (pv === cardData.length - 1) {
            return 0;
          }
          return pv + 1;
        });
      }
    }, AUTO_DELAY);
    return () => clearInterval(intervalRef);
  }, []);

  const onDragEnd = () => {
    const x = dragX.get();
    if (x <= -DRAG_BUFFER && cardIndex < cardData.length - 1) {
      setCardIndex((pv) => pv + 1);
    } else if (x >= DRAG_BUFFER && cardIndex > 0) {
      setCardIndex((pv) => pv - 1);
    }
  };

  const Cards = ({ cardIndex }) => {
    return (
      <>
        {cardData.map((card, idx) => {
          const Icon = card.icon;
          return (
            <motion.div
              key={idx}
              animate={{
                scale: cardIndex === idx ? 0.95 : 0.85,
              }}
              transition={SPRING_OPTIONS}
              className="w-full shrink-0 px-4 flex justify-center"
            >
              <Card className="w-[100%] sm:w-[70%] mt max-w-md shadow-lg">
                <CardContent className="p-6 space-y-4">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 rounded-full bg-gray-100 shadow-md">
                      <Icon className="text-3xl text-black" />
                    </div>
                  </div>
                  <h2 className="text-base sm:text-xl font-black text-black text-center">
                    {card.title}
                  </h2>
                  <p className="text-xs sm:text-base text-[#86868B] font-semibold text-center">
                    {card.description}
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          );
        })}
      </>
    );
  };

  const Dots = ({ cardIndex, setCardIndex }) => {
    return (
      <div className="mt-4 flex w-full justify-center gap-2">
        {cardData.map((_, idx) => {
          return (
            <button
              key={idx}
              onClick={() => setCardIndex(idx)}
              className={`h-1 w-1 rounded-full transition-colors ${idx === cardIndex ? "bg-neutral-800" : "bg-neutral-300"
                }`}
            />
          );
        })}
      </div>
    );
  };

  const GradientEdges = () => {
    return (
      <>
        <div className="pointer-events-none absolute bottom-0 left-0 top-0 w-[10vw] max-w-[100px] bg-gradient-to-r from-white/50 to-white/0" />
        <div className="pointer-events-none absolute bottom-0 right-0 top-0 w-[10vw] max-w-[100px] bg-gradient-to-l from-white/50 to-white/0" />
      </>
    );
  };

  return (
    <div className='about-page'>
      <Navbar />
      <div className="h-screen bg-white p-8 flex items-center justify-center">
        <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="space-y-6">
            <span className="bg-rebite-orange text-white px-4 py-2 rounded-full text-sm font-black">
              Our Mission
            </span>
            <h1 className="text-xl sm:text-4xl text-black font-black leading-tight">
              Reducing Food Waste by Increasing Sales, One Local Restaurant at a Time
            </h1>
            <p className="text-[#86868B] font-semibold text-sm sm:text-lg leading-tight text-left">
              Rebite is more than just a food app. Our goal is to drive down the global food waste total by giving power back to the restaurants, helping them sell their delicious food options to the public so everyone wins.
            </p>
          </div>
          <div className="relative overflow-hidden sm:py-8">
            <motion.div
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              style={{ x: dragX }}
              animate={{ translateX: `-${cardIndex * 100}%` }}
              transition={SPRING_OPTIONS}
              onDragEnd={onDragEnd}
              className="flex cursor-grab items-center active:cursor-grabbing"
            >
              <Cards cardIndex={cardIndex} />
            </motion.div>
            <Dots cardIndex={cardIndex} setCardIndex={setCardIndex} />
            <GradientEdges />
          </div>
        </div>
      </div>
      <div className='h-[15vh] md:h-[20vh] bg-[#D9D9D9]'>
        <div className="grid grid-cols-3 gap-8 px-4 py-8 md:grid-cols-3 text-black max-w-6xl mx-auto">
          <div className="flex flex-col items-center md:items-start">
            <img src={Logo} alt="Logo" className="md:w-24 mb-2" />
            <p className="text-xs md:text-sm mb-1">contact@getrebite.com</p>
            <p className="text-xs md:text-sm">© 2024, Rebite Inc.</p>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <a href="https://apps.apple.com/us/app/rebite/id6618117899" className="text-sm md:text-base text-black mb-2">
              Download Rebite
            </a>
            <a href="/contact" className="text-sm md:text-base text-black">Support</a>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <a href="/terms" className="text-sm md:text-base text-black mb-2">Terms of Service</a>
            <a href="/privacy" className="text-sm md:text-base text-black">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};
