import React, { useState, useEffect } from 'react';
import { auth } from '../../config/firebaseSetup';
import { Vendornavbar } from '../Vendornav';
import { getVendorOrders } from '../../API/vendorAPI';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card.tsx";
import { Button } from "../ui/button.tsx";
import { Badge } from "../ui/badge.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table.tsx";
import { useNavigate } from 'react-router-dom';

export const Orders = () => {
  const [completedOrders, setCompletedOrders] = useState([]);
  const [incompletedOrders, setIncompletedOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
            const unsubscribe = auth.onAuthStateChanged(user => {
                if (user) {
                    console.log("user is logged in");
                } else {
                    navigate('/login');
                }
            });
            return () => unsubscribe();
        }, [navigate]);

  useEffect(() => {
    fetchVendorOrders();
  }, []);

  const fetchVendorOrders = async () => {
    try {
      const vendorOrders = await getVendorOrders();
      setCompletedOrders(vendorOrders.completedOrders);
      setIncompletedOrders(vendorOrders.incompletedOrders);
    } catch (error) {
      console.error('Failed to fetch menu items:', error);
    }
  };

  const handleMarkComplete = async (orderId) => {
    try {
      console.log("updating the order id:", orderId);
      //await markOrderComplete(orderId);
      //fetchVendorOrders();
    } catch (error) {
      console.error('Failed to mark order as complete:', error);
    }
  };

  const renderOrderTable = (orders, isCompleted) => (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Status</TableHead>
          <TableHead>User Name</TableHead>
          <TableHead>Items</TableHead>
          <TableHead>Quantity</TableHead>
          <TableHead>{isCompleted ? 'Completion Time' : 'Order Time'}</TableHead>
          {!isCompleted && <TableHead className="w-[100px]">Action</TableHead>}
        </TableRow>
      </TableHeader>
      <TableBody>
        {orders.map((order, index) => (
          <TableRow key={index}>
            <TableCell>
              <Badge 
                variant={isCompleted ? "secondary" : "default"}
                className="font-medium"
              >
                {isCompleted ? 'Completed' : 'Pending'}
              </Badge>
            </TableCell>
            <TableCell className="font-medium">{order.usersName}</TableCell>
            <TableCell>{order.foodItem.join(', ')}</TableCell>
            <TableCell>{order.foodQuantity.join(', ')}</TableCell>
            <TableCell>{isCompleted ? order.completionTime : order.orderTime}</TableCell>
            {!isCompleted && (
              <TableCell>
                <Button 
                  variant="secondary"
                  size="sm"
                  onClick={() => handleMarkComplete(order.id)}
                >
                  Complete
                </Button>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Vendornavbar />
      <div className="container mx-auto py-10 space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold tracking-tight mt-10">Orders</h1>
        </div>
        
        <Card>
          <CardHeader>
            <CardTitle>Incomplete Orders</CardTitle>
          </CardHeader>
          <CardContent>
            {incompletedOrders.length > 0 ? (
              renderOrderTable(incompletedOrders, false)
            ) : (
              <p className="text-center text-muted-foreground py-4">No incomplete orders</p>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Completed Orders</CardTitle>
          </CardHeader>
          <CardContent>
            {completedOrders.length > 0 ? (
              renderOrderTable(completedOrders, true)
            ) : (
              <p className="text-center text-muted-foreground py-4">No completed orders</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};