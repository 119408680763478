import React, { useState, useEffect } from 'react';
import { fdb, auth } from '../../config/firebaseSetup';
import { Vendornavbar } from '../../components/Vendornav';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, arrayUnion, writeBatch, updateDoc, setDoc } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from 'react-modal';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog.tsx";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card.tsx";
import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import { Label } from "../ui/label.tsx";
import { Progress } from "../ui/progress.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select.tsx";
import { Separator } from "../ui/separator.tsx";
import { PlusCircle, X, Upload, Loader2, Image as ImageIcon, Trash2 } from "lucide-react";
import { fetchMenuItems } from '../../API/menuAPI';
import { addMenuItems } from '../../API/menuAPI';
import { saveUpdatedItem } from '../../API/menuAPI';
import { deleteMenuItem } from '../../API/menuAPI';
import { getVendorName } from '../../API/vendorAPI';
import { createMenuFromFile } from '../../API/openAIAPI';
import placeholderImage from '../../Assets/placeholder.png';
import LoadingAnimation from '../loading';

export const CreateMenuItem = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productDescription, setProductDescription] = useState(''); 
    const [image, setImage] = useState(null); 
    const [imagePreview, setImagePreview] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const [vendorName, setVendorName] = useState('');
    const [mealType, setMealType] = useState('Breakfast');
    const [items, setItems] = useState([]);
    const [menuItems, setMenuItems] = useState({
        Breakfast: [],
        Lunch: [],
        Dinner: [],
        Drinks: [],
        Dessert: []
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(null); 
    const [menuImage, setMenuImage] = useState(null);
    const [isProcessingImage, setIsProcessingImage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setVendorId(user.uid);
                fetchVendorName();
                fetchsetMenuItems();
            } else {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const fetchsetMenuItems = async () => {
        try {
            const fetchedMenuItems = await fetchMenuItems();
            // Ensure we always have a valid object with arrays
            setMenuItems({
                Breakfast: fetchedMenuItems?.Breakfast || [],
                Lunch: fetchedMenuItems?.Lunch || [],
                Dinner: fetchedMenuItems?.Dinner || [],
                Drinks: fetchedMenuItems?.Drinks || [],
                Dessert: fetchedMenuItems?.Dessert || []
            });
        } catch (error) {
            console.error('Failed to fetch menu items:', error);
            // Keep the default empty arrays on error
        }
    };

    const addsetMenuItems = async () => {
        try {
            const itemsWithImages = await Promise.all(items.map(async (item) => {
                let imageUrl;
                
                if (item.image && item.image instanceof File) {
                    try {
                        imageUrl = await uploadImage(item.image);
                    } catch (error) {
                        console.error("Error uploading image:", error);
                        // Use placeholder image if upload fails
                        const placeholderFile = await getPlaceholderImageFile();
                        imageUrl = await uploadImage(placeholderFile);
                    }
                } else {
                    // If no image provided, use placeholder
                    const placeholderFile = await getPlaceholderImageFile();
                    imageUrl = await uploadImage(placeholderFile);
                }
    
                return {
                    name: item.name,
                    price: parseFloat(item.price),
                    description: item.description,
                    mealType: item.mealType,
                    img_url: imageUrl
                };
            }));
    
            const result = await addMenuItems(itemsWithImages);
            
            if (result) {
                console.log("Menu items added successfully");
                const newMenuItems = { ...menuItems };
                itemsWithImages.forEach(item => {
                    if (newMenuItems[item.mealType]) {
                        newMenuItems[item.mealType].push(item);
                    } else {
                        newMenuItems[item.mealType] = [item];
                    }
                });
                setMenuItems(newMenuItems);
                setItems([]);
                setModalIsOpen(false);
            } else {
                alert("Error adding menu items");
            }
        } catch (error) {
            console.error("Error adding and updating new menu items: ", error);
            alert("Failed to add menu items. Please try again.");
        }
    };

    const fetchVendorName = async () => {
        try {
            const fetchedVendorName = await getVendorName();
            setVendorName(fetchedVendorName);
        } catch (error) {
            console.error('Failed to fetch vendor name: ', error)
        }
    };

    const handleAddMore = () => { 
        if(productName && productPrice && productDescription && (image || imagePreview) && mealType){ 
            const newItem = {
                name: productName,
                price: parseFloat(productPrice),
                description: productDescription,
                mealType: mealType, 
                image: image, // Add the actual image file
                image_url: imagePreview // Add the preview URL
            };
            setItems(prevItems => [...prevItems, newItem]);
            resetFormFields();
        } else {
            alert("All fields must be filled");
        }
    }; 

    const uploadImage = async (file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `menu-items/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };   

    const getPlaceholderImageFile = async () => {
        const response = await fetch(placeholderImage);
        const blob = await response.blob();
        return new File([blob], "placeholder.png", { type: "image/png" });
    };

    const resetFormFields = () => {
        setProductName('');
        setProductPrice('');
        setProductDescription('');
        setMealType('Breakfast'); 
        setImage(null); 
        setImagePreview(null);
    };

    const handleUpdateItem = (item, index) => {
        setProductName(item.name);
        setProductPrice(item.price.toString());
        setProductDescription(item.description);
        const itemMealType = item.meal_type || item.mealType;
        setMealType(itemMealType);
        setImagePreview(item.img_url || item.img);
        setIsUpdating(true);
        setItemToUpdate({
            ...item,
            index,
            mealType: itemMealType,
            old_img_url: item.img_url || item.img 
        });
        
        const dialogTrigger = document.querySelector('[data-dialog-trigger]');
        if (dialogTrigger) {
            dialogTrigger.click();
        }
    };

    const updatesetUpdatedItem = async () => {
        if (!vendorId || !itemToUpdate) {
            alert("Vendor ID and item to update are required.");
            return;
        }
    
        try {
            setIsSubmitting(true);
            
            const formData = new FormData();
            
            if (image instanceof File) {
                formData.append('image', image);
            }
            
            const updatedItem = {
                name: productName,
                price: parseFloat(productPrice),
                description: productDescription,
                mealType: mealType,
                index: itemToUpdate.index,
                oldType: itemToUpdate.mealType || itemToUpdate.meal_type,
                hasNewImage: image instanceof File,
                img_url: image instanceof File ? null : imagePreview, 
                old_img_url: itemToUpdate.img_url || itemToUpdate.image_url
            };
            
            formData.append('updatedItem', JSON.stringify(updatedItem));
    
            const updatedItems = await saveUpdatedItem(formData);
            
            if (updatedItems) {
                setMenuItems(prevMenuItems => {
                    const newMenuItems = { ...prevMenuItems };
                    newMenuItems[mealType] = newMenuItems[mealType].map((item, idx) => 
                        idx === itemToUpdate.index ? updatedItems[0] : item
                    );
                    return newMenuItems;
                });
                
                resetFormFields();
                setIsUpdating(false);
                setItemToUpdate(null);
                const closeButton = document.querySelector('[data-dialog-close]');
                if (closeButton) {
                    closeButton.click();
                }
            }
        } catch (error) {
            console.error("Error updating menu items: ", error);
            alert("Failed to update menu item. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };
    


    const deletesetMenuItem = async (item) => {
        if (!vendorId) {
            alert("Vendor ID is required.");
            return;
        }
        try {
            const normalizedItem = {
                ...item,
                meal_type: item.mealType || item.meal_type 
            };
    
            const updatedItems = await deleteMenuItem(normalizedItem);
            if (updatedItems) {
                setMenuItems(prevMenuItems => {
                    const newMenuItems = { ...prevMenuItems };
                    const mealType = normalizedItem.meal_type;
                    newMenuItems[mealType] = updatedItems;
                    return newMenuItems;
                });
            } else {
                alert("Error deleting menu item");
            }
        } catch (error) {
            console.error("Error deleting menu item: ", error);
            alert("Failed to delete menu item. Please try again.");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isUpdating) {
            updatesetUpdatedItem();
        } else {
            addsetMenuItems();
        }
    };

    const handleRemoveItem = (index) => {
        setItems(prevItems => prevItems.filter((_, i) => i !== index));
    }; 

    const handleMenuImageUpload = async (event) => { 
        const file = event.target.files[0];
        if (!file) return;
        
        setMenuImage(file);
        setIsProcessingImage(true); 
        setUploadProgress(0);

        const menuImageFile = new FormData();
        menuImageFile.append('file', file);

        try {
            const progressInterval = setInterval(() => {
                setUploadProgress(prev => {
                    if (prev >= 90) {
                        clearInterval(progressInterval);
                        return 90;
                    }
                    return prev + 10;
                });
            }, 500);

            const response = await createMenuFromFile(menuImageFile);
            clearInterval(progressInterval);
            setUploadProgress(100);
            if (response) {
                await addMenuItems(response);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error processing menu image:', error);
            alert(`Failed to process menu image. Error: ${error.message}`);
        } finally {
            setIsProcessingImage(false);
            setUploadProgress(0);
        }
    };

    const LoadingOverlay = () => (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <Card className="w-full max-w-md mx-4">
                <CardHeader>
                    <CardTitle className="text-center">Processing Your Menu</CardTitle>
                    <CardDescription className="text-center">
                        Please wait while we process your menu items
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6 p-6">
                    <div className="flex flex-col items-center gap-4">
                        <Loader2 className="h-8 w-8 animate-spin text-orange-500" />
                        <Progress value={uploadProgress} className="w-full" />
                        <p className="text-sm text-gray-500">
                            {uploadProgress < 100 
                                ? "Analyzing and processing your menu..." 
                                : "Almost done! Finalizing menu items..."}
                        </p>
                    </div>
                </CardContent>
            </Card>
        </div>
    );

    const isMenuEmpty = !menuItems || 
        Object.values(menuItems).every(category => 
            Array.isArray(category) && category.length === 0
        );

    return (
        <div className="min-h-screen bg-gray-50">
            <Vendornavbar />
            {isProcessingImage && <LoadingOverlay />}
            
            <div className="container mx-auto px-4 pt-24">
                {menuItems && Object.keys(menuItems).length > 0 && 
                 Object.values(menuItems).some(items => items.length > 0) && (
                    <div className="flex justify-center mb-8">
                        <Dialog>
                        <DialogTrigger asChild>
                                <Button 
                                    data-dialog-trigger
                                    className="bg-gradient-to-r from-orange-500 to-amber-500 hover:from-orange-600 hover:to-amber-600 text-white"
                                >
                                    <PlusCircle className="mr-2 h-4 w-4" />
                                    Create Menu Item
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[600px]">
                                <button
                                    data-dialog-close
                                    className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                                >
                                    <X className="h-4 w-4" />
                                    <span className="sr-only">Close</span>
                                </button>
                                <DialogHeader>
                                    <DialogTitle>
                                        Create Menu Item
                                    </DialogTitle>
                                </DialogHeader>
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div className="space-y-4">
                                        <div>
                                            <Label htmlFor="mealType">Menu Category</Label>
                                            <Select value={mealType} onValueChange={setMealType}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select category" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="Breakfast">Breakfast</SelectItem>
                                                    <SelectItem value="Lunch">Lunch</SelectItem>
                                                    <SelectItem value="Dinner">Dinner</SelectItem>
                                                    <SelectItem value="Drinks">Drinks</SelectItem>
                                                    <SelectItem value="Dessert">Dessert</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <Label htmlFor="name">Item Name</Label>
                                                <Input
                                                    id="name"
                                                    value={productName}
                                                    onChange={e => setProductName(e.target.value)}
                                                    placeholder="Menu item name"
                                                />
                                            </div>
                                            <div>
                                                <Label htmlFor="price">Price</Label>
                                                <Input
                                                    id="price"
                                                    type="number"
                                                    value={productPrice}
                                                    onChange={e => setProductPrice(e.target.value)}
                                                    placeholder="Price"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <Label htmlFor="description">Description</Label>
                                            <Input
                                                id="description"
                                                value={productDescription}
                                                onChange={e => setProductDescription(e.target.value)}
                                                placeholder="Description"
                                            />
                                        </div>

                                        <div>
                                            <Label htmlFor="image">Upload Image</Label>
                                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-300 px-6 py-10">
                                                <div className="text-center">
                                                    <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                        <label className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500">
                                                            <span>Upload a file</span>
                                                            <input
                                                                type="file"
                                                                className="sr-only"
                                                                onChange={e => {
                                                                    const file = e.target.files[0];
                                                                    setImage(file);
                                                                    const previewURL = URL.createObjectURL(file);
                                                                    setImagePreview(previewURL);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {imagePreview && (
                                                <div className="mt-4 relative">
                                                    <img src={imagePreview} alt="Preview" className="w-full max-w-[200px] rounded-lg" />
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setImage(null);
                                                            setImagePreview(null);
                                                        }}
                                                        className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-lg"
                                                    >
                                                        <X className="h-4 w-4" />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex justify-end gap-4">
                                        {isUpdating ? (
                                            <Button 
                                                type="button"
                                                onClick={updatesetUpdatedItem}
                                                className="bg-gradient-to-r from-orange-500 to-amber-500"
                                            >
                                                Update Item
                                            </Button>
                                        ) : (
                                            <>
                                                <Button 
                                                    type="button"
                                                    onClick={handleAddMore}
                                                    variant="outline"
                                                >
                                                    Add More
                                                </Button>
                                                <Button 
                                                    type="submit"
                                                    className="bg-gradient-to-r from-orange-500 to-amber-500"
                                                >
                                                    Submit All
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </form>

                                {items.length > 0 && (
                                    <div className="mt-6 space-y-4">
                                        <Separator />
                                        <h3 className="font-medium">Added Items</h3>
                                        <div className="space-y-4">
                                            {items.map((item, index) => (
                                                <Card key={index}>
                                                    <CardContent className="flex items-center justify-between p-4">
                                                        <div className="flex items-center space-x-4">
                                                            {(item.image || item.image_url) && (
                                                                <img
                                                                    src={item.image ? URL.createObjectURL(item.image) : item.image_url}
                                                                    alt={item.name}
                                                                    className="h-12 w-12 rounded-lg object-cover"
                                                                />
                                                            )}
                                                            <div>
                                                                <p className="font-medium">{item.name}</p>
                                                                <p className="text-sm text-gray-500">${item.price}</p>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            variant="ghost"
                                                            size="icon"
                                                            onClick={() => handleRemoveItem(index)}
                                                        >
                                                            <Trash2 className="h-4 w-4" />
                                                        </Button>
                                                    </CardContent>
                                                </Card>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </DialogContent>
                        </Dialog>
                    </div>
                )}

                <div className="space-y-8">
                    {Object.keys(menuItems).map((type) => (
                        menuItems[type].length > 0 && (
                            <Card key={type}>
                                <CardHeader>
                                    <CardTitle>{type}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                        {menuItems[type].map((item, index) => (
                                            <Card key={index}>
                                                <CardContent className="p-0">
                                                    <div className="relative">
                                                        <img
                                                            src={item.img_url || item.image_url || placeholderImage}
                                                            alt={item.name}
                                                            className="w-full h-48 object-cover rounded-t-lg"
                                                            onError={(e) => {
                                                                e.target.src = placeholderImage;
                                                            }}
                                                        />
                                                        <Button
                                                            variant="ghost"
                                                            size="icon"
                                                            className="absolute top-2 right-2 bg-white/80 hover:bg-white"
                                                            onClick={() => deletesetMenuItem(item)}
                                                        >
                                                            <X className="h-4 w-4" />
                                                        </Button>
                                                    </div>
                                                    <div className="p-4 space-y-2">
                                                        <h3 className="font-semibold">{item.name}</h3>
                                                        <p className="text-sm text-gray-500">{item.description}</p>
                                                        <div className="flex justify-between items-center">
                                                            <p className="font-medium">${parseFloat(item.price).toFixed(2)}</p>
                                                            <Button
                                                                variant="outline"
                                                                size="sm"
                                                                onClick={() => handleUpdateItem(item, index)}
                                                            >
                                                                Update
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        )
                    ))}
                </div>

                {isMenuEmpty && (
                <div className="flex items-center justify-center min-h-[60vh]">
                    <Card className="w-full max-w-md">
                        <CardHeader>
                            <CardTitle>Express Onboarding</CardTitle>
                            <CardDescription>Upload your menu to get started quickly</CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-6">
                            <div className="flex justify-center">
                                <label className="flex flex-col items-center gap-2 cursor-pointer">
                                    <div className={`
                                        p-4 bg-gray-50 rounded-lg border-2 border-dashed 
                                        ${isProcessingImage 
                                            ? 'border-orange-500 bg-orange-50' 
                                            : 'border-gray-300 hover:border-orange-500 hover:bg-orange-50'
                                        } 
                                        transition-colors duration-200
                                    `}>
                                        {isProcessingImage ? (
                                            <Loader2 className="h-12 w-12 text-orange-500 animate-spin" />
                                        ) : (
                                            <Upload className="h-12 w-12 text-gray-400" />
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        className="sr-only"
                                        onChange={handleMenuImageUpload}
                                        accept="image/*, jpeg,png"
                                        disabled={isProcessingImage}
                                    />
                                    <span className="text-sm text-gray-500">
                                        {isProcessingImage 
                                            ? 'Processing your menu...' 
                                            : 'Upload menu (JPEG, PNG, PDF)'}
                                    </span>
                                </label>
                            </div>
                                
                                <div className="flex justify-center">
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button className="bg-gradient-to-r from-orange-500 to-amber-500">
                                                <PlusCircle className="mr-2 h-4 w-4" />
                                                Or Manually Create Menu Item
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent className="sm:max-w-[600px]">
                                <DialogHeader>
                                    <DialogTitle>Create Menu Item</DialogTitle>
                                </DialogHeader>
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div className="space-y-4">
                                        <div>
                                            <Label htmlFor="mealType">Menu Category</Label>
                                            <Select value={mealType} onValueChange={setMealType}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select category" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="Breakfast">Breakfast</SelectItem>
                                                    <SelectItem value="Lunch">Lunch</SelectItem>
                                                    <SelectItem value="Dinner">Dinner</SelectItem>
                                                    <SelectItem value="Drinks">Drinks</SelectItem>
                                                    <SelectItem value="Dessert">Dessert</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <Label htmlFor="name">Item Name</Label>
                                                <Input
                                                    id="name"
                                                    value={productName}
                                                    onChange={e => setProductName(e.target.value)}
                                                    placeholder="Menu item name"
                                                />
                                            </div>
                                            <div>
                                                <Label htmlFor="price">Price</Label>
                                                <Input
                                                    id="price"
                                                    type="number"
                                                    value={productPrice}
                                                    onChange={e => setProductPrice(e.target.value)}
                                                    placeholder="Price"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <Label htmlFor="description">Description</Label>
                                            <Input
                                                id="description"
                                                value={productDescription}
                                                onChange={e => setProductDescription(e.target.value)}
                                                placeholder="Description"
                                            />
                                        </div>

                                        <div>
                                            <Label htmlFor="image">Upload Image</Label>
                                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-300 px-6 py-10">
                                                <div className="text-center">
                                                    <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                        <label className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500">
                                                            <span>Upload a file</span>
                                                            <input
                                                                type="file"
                                                                className="sr-only"
                                                                onChange={e => {
                                                                    const file = e.target.files[0];
                                                                    setImage(file);
                                                                    const previewURL = URL.createObjectURL(file);
                                                                    setImagePreview(previewURL);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {imagePreview && (
                                                <div className="mt-4 relative">
                                                    <img src={imagePreview} alt="Preview" className="w-full max-w-[200px] rounded-lg" />
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setImage(null);
                                                            setImagePreview(null);
                                                        }}
                                                        className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-lg"
                                                    >
                                                        <X className="h-4 w-4" />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex justify-end gap-4">
                                        {isUpdating ? (
                                            <Button 
                                                type="button"
                                                onClick={updatesetUpdatedItem}
                                                className="bg-gradient-to-r from-orange-500 to-amber-500"
                                            >
                                                Update Item
                                            </Button>
                                        ) : (
                                            <>
                                                <Button 
                                                    type="button"
                                                    onClick={handleAddMore}
                                                    variant="outline"
                                                >
                                                    Add More
                                                </Button>
                                                <Button 
                                                    type="submit"
                                                    className="bg-gradient-to-r from-orange-500 to-amber-500"
                                                >
                                                    Submit All
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </form>

                                {items.length > 0 && (
                                    <div className="mt-6 space-y-4">
                                        <Separator />
                                        <h3 className="font-medium">Added Items</h3>
                                        <div className="space-y-4">
                                            {items.map((item, index) => (
                                                <Card key={index}>
                                                    <CardContent className="flex items-center justify-between p-4">
                                                        <div className="flex items-center space-x-4">
                                                            {item.image && (
                                                                <img
                                                                    src={URL.createObjectURL(item.image)}
                                                                    alt={item.name}
                                                                    className="h-12 w-12 rounded-lg object-cover"
                                                                />
                                                            )}
                                                            <div>
                                                                <p className="font-medium">{item.name}</p>
                                                                <p className="text-sm text-gray-500">${item.price}</p>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            variant="ghost"
                                                            size="icon"
                                                            onClick={() => handleRemoveItem(index)}
                                                        >
                                                            <Trash2 className="h-4 w-4" />
                                                        </Button>
                                                    </CardContent>
                                                </Card>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </DialogContent>
                                    </Dialog>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </div>
        </div>
    );
};
