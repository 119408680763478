import React from 'react'
import { Navbar } from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { fdb, storage } from '../config/firebaseSetup'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, GeoPoint, setDoc, doc } from "firebase/firestore";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card.tsx";
import { Input } from "./ui/input.tsx";
import { Button } from "./ui/button.tsx";
import { Label } from "./ui/label.tsx";
import { Textarea } from "./ui/textarea.tsx";
import { Alert, AlertDescription } from "./ui/alert.tsx";
import { Eye, EyeOff } from "lucide-react";
import { auth } from '../config/firebaseSetup'
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth' 
import { createVendor } from './/../API/vendorAPI';
import axios from 'axios'

export const VendorRegistration = () => {
    const [formData, setFormData] = useState({
        vendorName: '',
        vendorAddress: '',
        vendorNumber: '',
        vendorEmail: '',
        vendorDescription: '',
        password: '',
        confirmPassword: '',
    });

    const [vendorImage, setVendorImage] = useState(null);
    const [error, setError] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validatePassword = () => {
        if (formData.password !== '' || formData.confirmPassword !== '') {
            if (formData.password !== formData.confirmPassword) {
                setError('Passwords do not match');
                return false;
            }
        }
        return true;
    };


    const vendorRegister = async (e) => {
        e.preventDefault();

        if (!vendorImage) {
            setError('Vendor image is required');
            return;
        }

        try {
            const vendorData = { 
                name: formData.vendorName,
                address: formData.vendorAddress, 
                img_url:"",
                phone_number: formData.vendorNumber,
                email: formData.vendorEmail,
                description: formData.vendorDescription,
                coordinates: new GeoPoint(0, 0),
            };

            if (validatePassword()) {
                const userCredential = await createUserWithEmailAndPassword(auth, formData.vendorEmail, formData.password); 
                const user = userCredential.user; 

                await signInWithEmailAndPassword(auth, formData.vendorEmail, formData.password); 


                const vendorRef = doc(fdb, "Vendors", user.uid);
                const imageRef = ref(storage, `vendorImages/${user.uid}`);

                await uploadBytes(imageRef, vendorImage);
                const img = await getDownloadURL(imageRef);
                vendorData.img_url = img;

                const result = await createVendor(vendorData); 

                if (result) {
                    // Vendor was created successfully
                    console.log("Vendor created successfully:", result);
                    
                    // Reset the form
                    setFormData({
                        vendorName: '',
                        vendorAddress: '',
                        vendorNumber: '',
                        vendorEmail: '',
                        vendorDescription: '',
                        password: '',
                        confirmPassword: '',
                    });
    
                    // Optional: navigate to a verification page
                    // navigate('/verify-email');
                } else {
                    setError("Error creating vendor");
                }
                //navigate('/verify-email');
            }
        } catch (error) {
            console.error("Error adding document: ", error);
            setError(error.message);
        }
    };

    const isFormComplete =
        formData.vendorName &&
        formData.vendorAddress &&
        formData.vendorNumber &&
        formData.vendorEmail &&
        formData.password &&
        formData.confirmPassword &&
        formData.vendorDescription &&
        vendorImage;

    return (
        <div>
            <Navbar />
            <div className="min-h-screen bg-gray-50">
                <div className="container mx-auto px-4 py-24">
                    <Card className="max-w-2xl mx-auto">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">Vendor Registration</CardTitle>
                        </CardHeader>
                        <CardContent>
                            {error && (
                                <Alert variant="destructive" className="mb-6">
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}

                            <form onSubmit={vendorRegister} className="space-y-6">
                                <div className="space-y-2">
                                    <Label htmlFor="vendorName">Vendor Name</Label>
                                    <Input
                                        id="vendorName"
                                        name="vendorName"
                                        value={formData.vendorName}
                                        onChange={handleInputChange}
                                        placeholder="Enter your name"
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="vendorAddress">Vendor Address</Label>
                                    <Input
                                        id="vendorAddress"
                                        name="vendorAddress"
                                        value={formData.vendorAddress}
                                        onChange={handleInputChange}
                                        placeholder="Enter your address"
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="vendorNumber">Phone Number</Label>
                                    <Input
                                        id="vendorNumber"
                                        name="vendorNumber"
                                        value={formData.vendorNumber}
                                        onChange={handleInputChange}
                                        placeholder="Enter your phone number"
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="vendorEmail">Email</Label>
                                    <Input
                                        id="vendorEmail"
                                        name="vendorEmail"
                                        type="email"
                                        value={formData.vendorEmail}
                                        onChange={handleInputChange}
                                        placeholder="Enter your email address"
                                        required
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="vendorImage">Vendor Image</Label>
                                    <Input
                                        id="vendorImage"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => setVendorImage(e.target.files[0])}
                                        required
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="space-y-2">
                                        <Label htmlFor="password">Password</Label>
                                        <div className="relative">
                                            <Input
                                                id="password"
                                                name="password"
                                                type={isPasswordVisible ? 'text' : 'password'}
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                placeholder="Enter your password"
                                                required
                                            />
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                size="icon"
                                                className="absolute right-2 top-1/2 -translate-y-1/2"
                                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                            >
                                                {isPasswordVisible ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="space-y-2">
                                        <Label htmlFor="confirmPassword">Confirm Password</Label>
                                        <div className="relative">
                                            <Input
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                                value={formData.confirmPassword}
                                                onChange={handleInputChange}
                                                placeholder="Re-enter your password"
                                                required
                                            />
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                size="icon"
                                                className="absolute right-2 top-1/2 -translate-y-1/2"
                                                onClick={() => setConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                            >
                                                {isConfirmPasswordVisible ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="vendorDescription">Vendor Description</Label>
                                    <Textarea
                                        id="vendorDescription"
                                        name="vendorDescription"
                                        value={formData.vendorDescription}
                                        onChange={handleInputChange}
                                        placeholder="Type here"
                                        required
                                        className="h-32"
                                    />
                                </div>

                                <Button
                                    type="submit"
                                    className="w-full"
                                    disabled={!isFormComplete}
                                >
                                    Register
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};