import React, { useState, useEffect, useRef } from 'react';
import { fdb, auth } from '../../config/firebaseSetup';
import { Vendornavbar } from '../Vendornav';
import { getDocs, collection, addDoc, updateDoc, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchMenuItems } from '../../API/menuAPI';
import { fetchVendorFlashSales, createFlashSale, deletePastSale, fetchSaleById, updateFlashSale, deleteFlashSale, addToPast, fetchPastSales } from '../../API/flashsaleAPI';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog.tsx";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card.tsx";
import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import { Label } from "../ui/label.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select.tsx";
import { Clock, PlusCircle, Trash2, RefreshCcw, Edit2 } from "lucide-react";
async function copyVendorsToVendorDev() {
  try {
    const vendorsCollectionRef = collection(fdb, 'Vendors')
    const querySnapshot = await getDocs(vendorsCollectionRef);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const vendorDevCollectionRef = collection(fdb, 'VendorDev');
      setDoc(doc.ref, data, { merge: true });
    });

    console.log('Documents copied successfully from Vendors to VendorDev collection');
  } catch (error) {
    console.error('Error copying documents:', error);
  }
}

export const Flashsales = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [itemAmount, setItemAmount] = useState(0);
  const [itemLimit, setItemLimit] = useState(0);
  const [itemDescription, setItemDescription] = useState('');
  const [timeAmount, setTimeAmount] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [productImg, setProductImg] = useState(''); 
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [menuItems, setMenuItems] = useState({
    Breakfast: [],
    Lunch: [],
    Dinner: [],
    Drinks: [],
    Dessert: []
  });
  const [userMenuItems, setUserMenuItems] = useState({});
  const [flashSales, setFlashSales] = useState([]);
  const [pastSales, setPastSales] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentSaleId, setCurrentSaleId] = useState(null);
  const [reactivatingSale, setReactivatingSale] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchsetMenuItems();
        fetchsetFlashSales();
        fetchsetPastSales();
      } else {
        navigate('/login');
      }
    });

    const interval = setInterval(updateFlashSales, 1000);

    return () => {
      unsubscribe();
      clearInterval(interval);
    };
  }, []);

  const fetchsetMenuItems = async () => {
    const fetchedMenuItems = await fetchMenuItems();
    if (fetchedMenuItems) {
      setUserMenuItems(fetchedMenuItems);
    } else {
      alert('Error fetching all flash sales')
    }
  };

  const fetchsetFlashSales = async () => {
    try {
      const fetchedFlashSales = await fetchVendorFlashSales()
      console.log("This is what we loaded")
      console.log(fetchedFlashSales)
      setFlashSales(fetchedFlashSales);
    } catch (error) {
      console.log(error)
    }
  }

  const fetchsetPastSales = async () => {
    try {
      console.log("Do we fetch past sales")
      const fetchedPastSales = await fetchPastSales();
      console.log(fetchedPastSales)
      setPastSales(fetchedPastSales);
    } catch (error) {
      console.log(error)
    }
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedProduct('');
    setDiscountedPrice('');
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
    const product = userMenuItems[selectedCategory]?.find((item) => item.name === value);
  
    if (product) {
      console.log("We are getting this product: ", product)
      setProductImg(product.img_url); // Set the image URL in the state
      setItemDescription(product.description); // Set the description in the state
      calculateDiscountedPrice(value, discount);
    }
  };

  const handleDiscountChange = (event) => {
    const value = event.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
      setDiscount(value);
      calculateDiscountedPrice(selectedProduct, value);
    }
  };

  const handleAmountOfItemsChange = (event) => {
    const value = event.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
      setItemAmount(value);
    }
  };

  const handleTimeAmountChange = (event) => {
    setTimeAmount(event.target.value);
  };

  const handleItemLimitChange = (event) => {
    setItemLimit(event.target.value);
  }

  const calculateDiscountedPrice = (productName, discountValue) => {
    if (productName && discountValue !== '' && Number(discountValue) >= 0 && Number(discountValue) <= 100) {
      const product = userMenuItems[selectedCategory]?.find(item => item.name === productName);
      if (product) {
        const originalPrice = product.price;
        const discountAmount = (originalPrice * discountValue) / 100;
        const finalPrice = originalPrice - discountAmount;
        setDiscountedPrice(finalPrice.toFixed(2));
        setPrice(originalPrice);
      }
    } else {
      setDiscountedPrice('');
    }
  };

  const clearForm = () => {
    setSelectedCategory('');
    setSelectedProduct('');
    setDiscount('');
    setItemAmount('');
    setTimeAmount('');
    setDiscountedPrice('');
    setPrice('');
    setReactivatingSale(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    setErrorMessage('');
    if (!selectedCategory || !selectedProduct) {
      setErrorMessage('Please select a category and a product.');
      return;
    }
    if (discount === '' || Number(discount) < 0 || Number(discount) > 100) {
      setErrorMessage('Please enter a valid discount between 0 and 100.');
      return;
    }
    if (timeAmount === '') {
      setErrorMessage('Please enter a valid time for the sale.');
      return;
    }

    const [inputHours, inputMinutes] = timeAmount.split(':').map(Number);
    const now = new Date();
    const end_time = new Date(now.getFullYear(), now.getMonth(), now.getDate(), inputHours, inputMinutes);
    if (end_time < now) {
      end_time.setDate(end_time.getDate() + 1);
    }
    const totalMinutes = Math.floor((end_time - now) / 60000);

    const start_time = {
      seconds: Math.floor(now.getTime() / 1000),
      nanoseconds: 0
    };
  
    const end_timestamp = {
      seconds: Math.floor(end_time.getTime() / 1000),
      nanoseconds: 0
    }; 

    const saleData = {
      item_name: selectedProduct,
      discount: parseFloat(discount),
      base_price: parseFloat(price),
      duration: totalMinutes,
      start_time: new Date(start_time.seconds * 1000).toISOString(),
      end_time: new Date(end_timestamp.seconds * 1000).toISOString(),
      item_quantity: parseFloat(itemAmount),
      item_limit: parseFloat(itemLimit),
      item_description: itemDescription,
      img_url: productImg,
    };

    const user = auth.currentUser;
    if (user) {
      if (isEditMode && currentSaleId) {
        const check = await fetchSaleById(currentSaleId)
        if (check) {
          try {
            await updateFlashSale(saleData, currentSaleId)
            setFlashSales(currentSales => currentSales.map(sale => 
              sale.id === currentSaleId ? { ...saleData, id: currentSaleId } : sale
            ));
            setErrorMessage('');
            if (reactivatingSale) {
              await deletePastSale(currentSaleId);
              setPastSales(currentSales => currentSales.filter(sale => sale.id !== currentSaleId));
              setReactivatingSale(null);
            }
          } catch (error) {
            setErrorMessage(`Error updating flash sale: ${error.message}`);
          }
        } else {
          try {
            const docRefId = await createFlashSale(saleData);
            setFlashSales(currentSales => [...currentSales, { ...saleData, id: docRefId }]);
            setErrorMessage('');
            if (reactivatingSale) {
              await deletePastSale(currentSaleId);
              setPastSales(currentSales => currentSales.filter(sale => sale.id !== currentSaleId));
              setReactivatingSale(null);
            }
          } catch (error) {
            setErrorMessage(`Error adding flash sale: ${error.message}`);
          }
        }
      } else {
        try {
          const docRefId = await createFlashSale(saleData);
          setFlashSales(currentSales => [...currentSales, { ...saleData, id: docRefId }]);
          setErrorMessage('');
          if (reactivatingSale) {
            await deletePastSale(currentSaleId);
            setPastSales(currentSales => currentSales.filter(sale => sale.id !== currentSaleId));
            setReactivatingSale(null);
          }
        } catch (error) {
          setErrorMessage(`Error adding flash sale: ${error.message}`);
        }
      }
      clearForm();
      setIsEditMode(false);
      setCurrentSaleId(null);
      setIsDialogOpen(false); 
    } else {
      setErrorMessage('User is not authenticated');
    }
  };

  const editSale = (sale) => {
    setSelectedCategory(sale.category);
    setSelectedProduct(sale.productName);
    setDiscount((sale.discountedPrice / sale.originalPrice) * 100);
    setItemAmount(sale.item_nameAmount);
    setTimeAmount(`${Math.floor(sale.duration / 60).toString().padStart(2, '0')}:${(sale.duration % 60).toString().padStart(2, '0')}`);
    setDiscountedPrice(sale.discountedPrice);
    setIsEditMode(true);
    setCurrentSaleId(sale.id);
    setModalIsOpen(true);
  };

  const deleteSale = async (sale) => {
    const user = auth.currentUser;
    if (user) {
      console.log("WE ARE TRYING TO DELETE")
      console.log(sale.id)
      try {
        await deleteFlashSale(sale.id)
        setFlashSales(currentSales => currentSales.filter(currSale => currSale.id !== sale.id));
      } catch (error) {
        console.error("Error deleting document: ", error)
      }
    } 
  };

  const updateFlashSales = async () => {
    console.log("We are trying to update a flash sale")
    const now = new Date();
    const user = auth.currentUser;
    if (user) {
      setFlashSales(currentSales => {
        const updatedFlashSales = []

        for (const sale of currentSales) {
          const end_time = new Date(new Date(sale.start_time).getTime() + sale.duration * 60000);
          const remainingTime = Math.floor((end_time - now) / 1000);

          if (remainingTime <= 0) {
            console.log("OUT OF TIME")
            const saleData = { ...sale, end_time: end_time.toISOString() };
            (async () => {
              try {
                await addToPast(saleData, sale.id)
              } catch (error) {
                console.error("Error moving expired sale to PastSales: ", error);
              }
            })();
          } else {
            updatedFlashSales.push(sale);
          }
        }
        return updatedFlashSales
      });
      fetchsetPastSales(user.uid)
    }
  }

  const reactivateSale = (sale) => {
    setSelectedCategory(sale.category);
    setSelectedProduct(sale.productName);
    setDiscount((sale.discountedPrice / sale.originalPrice) * 100);
    setItemAmount(sale.item_nameAmount);
    setDiscountedPrice(sale.discountedPrice);
    setIsEditMode(true);
    setCurrentSaleId(sale.id);
    setReactivatingSale(sale);
    setTimeAmount('');
    setModalIsOpen(true);
  };

  return (
    <div className="min-h-screen bg-gray-50">
    <Vendornavbar />
    
    <div className="container mx-auto px-4 pt-24">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Flash Sales</h1>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button 
              onClick={() => setIsDialogOpen(true)}
              className="bg-gradient-to-r from-orange-500 to-amber-500 hover:from-orange-600 hover:to-amber-600 text-white"
            >
              <PlusCircle className="mr-2 h-4 w-4" />
              Create Flash Sale
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[500px]">
            <DialogHeader>
              <DialogTitle>{isEditMode ? 'Update Flash Sale' : 'Create New Flash Sale'}</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <Label>Meal Category</Label>
                  <Select value={selectedCategory} onValueChange={setSelectedCategory}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a category" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.keys(menuItems).map((category, index) => (
                        <SelectItem key={index} value={category}>{category}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div>
                  <Label>Product Name</Label>
                  <Select
                    value={selectedProduct}
                    onValueChange={(value) => handleProductChange(value)}
                    disabled={!selectedCategory}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a product" />
                    </SelectTrigger>
                    <SelectContent>
                      {selectedCategory && userMenuItems[selectedCategory]?.map((product, index) => (
                        <SelectItem key={index} value={product.name}>{product.name}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label>Discount (%)</Label>
                    <Input
                      type="number"
                      placeholder="Enter discount"
                      value={discount}
                      onChange={handleDiscountChange}
                    />
                  </div>
                  <div>
                    <Label>Items Amount</Label>
                    <Input
                      type="number"
                      placeholder="Number of items"
                      value={itemAmount}
                      onChange={handleAmountOfItemsChange}
                    />
                  </div>
                </div>

                {/* Added Item Limit field with disclaimer */}
                <div>
                  <Label>Item Limit</Label>
                  <Input
                    type="number"
                    placeholder="Limit on items per sale"
                    value={itemLimit}
                    onChange={handleItemLimitChange}
                  />
                  <p className="text-sm text-gray-500">The item limit is the maximum amount a user can order</p>
                </div>

                <div>
                  <Label>Sale Duration</Label>
                  <Input
                    type="time"
                    value={timeAmount}
                    onChange={handleTimeAmountChange}
                  />
                </div>

                {discountedPrice && (
                  <div>
                    <Label>Discounted Price</Label>
                    <Input value={`$${discountedPrice}`} readOnly />
                  </div>
                )}

                {errorMessage && (
                  <p className="text-sm text-red-500">{errorMessage}</p>
                )}
              </div>

              <div className="flex justify-end">
                <Button 
                  type="submit"
                  className="bg-gradient-to-r from-orange-500 to-amber-500"
                >
                  {isEditMode ? 'Update Sale' : 'Create Sale'}
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>

      {/* Active Sales Section */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Active Sales</CardTitle>
          <CardDescription>Currently running flash sales</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {flashSales.map((sale, index) => {
              const start_time = new Date(sale.start_time.seconds * 1000);
              const end_time = new Date(sale.end_time.seconds * 1000);
              const now = new Date();
              const remainingTime = Math.max(0, Math.floor((end_time - now) / 1000));
              const hours = Math.floor(remainingTime / 3600);
              const minutes = Math.floor((remainingTime % 3600) / 60);
              const seconds = remainingTime % 60;

              return (
                <Card key={index}>
                  <CardContent className="p-6">
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="font-semibold text-lg">{sale.item_name}</h3>
                        <p className="text-sm text-gray-500">{sale.category}</p>
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => deleteSale(sale)}
                        className="text-gray-500 hover:text-red-500"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                    
                    <div className="space-y-2">
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-500">Price:</span>
                        <span className="font-medium">
                          ${Math.round((sale.base_price - (sale.base_price * (sale.discount / 100))) * 100) / 100}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-500">Time Left:</span>
                        <span className="font-medium flex items-center">
                          <Clock className="h-4 w-4 mr-1" />
                          {`${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                        </span>
                      </div>
                    </div>

                    <Button
                      onClick={() => editSale(sale)}
                      variant="outline"
                      className="w-full mt-4"
                    >
                      <Edit2 className="h-4 w-4 mr-2" />
                      Edit Sale
                    </Button>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </CardContent>
      </Card>

      {/* Past Sales Section */}
      <Card>
        <CardHeader>
          <CardTitle>Past Sales</CardTitle>
          <CardDescription>Previously completed flash sales</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {pastSales.map((sale, index) => (
              <Card key={index}>
                <CardContent className="p-6">
                  <div className="mb-4">
                    <h3 className="font-semibold text-lg">{sale.item_name}</h3>
                    <p className="text-sm text-gray-500">{sale.category}</p>
                  </div>
                  
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-500">Final Price:</span>
                      <span className="font-medium">
                        ${Math.round((sale.base_price - (sale.base_price * (sale.discount / 100))) * 100) / 100}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-500">Ended:</span>
                      <span className="text-sm">
                        {new Date(sale.end_time.seconds * 1000).toLocaleString()}
                      </span>
                    </div>
                  </div>

                  <Button
                    onClick={() => reactivateSale(sale)}
                    variant="outline"
                    className="w-full mt-4"
                  >
                    <RefreshCcw className="h-4 w-4 mr-2" />
                    Reactivate
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
  );
};