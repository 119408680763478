import React, { useEffect, useState, useRef } from 'react';
import { Navbar } from './Navbar';
import { Link } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebaseSetup';

// Importing images
import homeImage1 from '../Assets/phoneHomeScreen.png';
import homeImage2 from '../Assets/pizzaKingApp.png';
import marketing from '../Assets/notification.png';
import sustainability from '../Assets/sustainibility.png';
import costBenefits from '../Assets/cost.png';
import efficiency from '../Assets/time.png';
import Logo from '../Assets/rebite logo large.png';
import homeImage from '../Assets/Phone Flower.png';
import parallaximage1 from '../Assets/Rebite Phone Backdrop.png';
import parallaximage2 from '../Assets/Notif Render.png';
import appstore from '../Assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

export const Home = () => {

  const IMG_PADDING = 12;
  const StickyImage = ({ img }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: targetRef,
      offset: ["end end", "end start"],
    });

    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
    const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

    const navigate = useNavigate();

    useEffect(() => {
              const subscribe = auth.onAuthStateChanged(user => {
                  if (user) {
                      navigate('/dashboard');
                  } else {
                      console.log("user isn't in");
                  }
              });
              return () => subscribe();
          }, [navigate]);

    return (
      <motion.div
        style={{
          backgroundImage: `url(${img})`,
          backgroundColor: 'rgba(0,0,0,0.1)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: `100vh`,
          top: IMG_PADDING,
          scale,
        }}
        ref={targetRef}
        className="sticky z-0 overflow-hidden"
      >
        <motion.div
          style={{
            opacity,
          }}
          className="absolute inset-0 bg-neutral-950/70"
        />
      </motion.div>
    );
  };

  const OverlayCopy = ({ subheading, heading }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: targetRef,
      offset: ["start end", "end start"],
    });

    const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
    const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

    return <motion.div
      ref={targetRef}
      style={{
        y,
        opacity,
      }}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className='mb-2 text-center text-xl md:mb-4 md:text-3xl'>{subheading}</p>
      <p className='text-center text-4xl font-bold md:text-7xl'>{heading}</p>
    </motion.div>
  }

  const TextParallaxContent = ({ img, subheading, heading, children }) => {
    return (
      <div>
        <div className="relative h-[150vh]">
          <StickyImage img={img} />
          <OverlayCopy subheading={subheading} heading={heading} />
        </div>
        {children}
      </div>
    );
  };


  return (
    <div className='landing-page'>
      <Navbar />
      <div className='images-text-container'>
        <div className="container mx-auto px-4 py-12 max-w-6xl">
          <div className="flex flex-col items-center text-center overflow-hidden">
            <div className="w-screen overflow-hidden flex justify-center">
              <img
                src={homeImage}
                alt="Rebite App Mockups with Phones"
                className="w-[110vw] lg:w-[60vw] max-w-none h-auto object-contain -mt-4 -mb-3"
              />
            </div>

            <h1 className="text-5xl font-bold bg-gradient-to-b from-rebite-orange to-rebite-yellow text-transparent bg-clip-text -mt-11">
              Bite Into Better Deals Today
            </h1>
            <p className="text-gray-700 text-2xl max-w-2xl leading-snug">
              The Rebite app helps connect users with local restaurants through flash sales to promote consistent restaurant revenue and food sustainability
            </p>
            <a href="https://apps.apple.com/us/app/rebite/id6618117899" target="_blank" rel="noopener noreferrer" className='w-[200vw] flex justify-center' >
              <img
                src={appstore}
                alt="Download Rebite on the App Store"
                className="mt-8"
              />
            </a>

          </div>
        </div>
      </div>

      {/* Parallax Zone */}
      <div className='bg-white'>
        <TextParallaxContent
          img={'https://firebasestorage.googleapis.com/v0/b/rebite-dev-f0771.appspot.com/o/Rebite%20Phone%20Backdrop.png?alt=media&token=673e9c4e-cca1-4969-9379-4e60af61c5de'}
          subheading=""
          heading="Discounts And Rewards. All In One Place.">
          <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
            <h2 className="col-span-1 text-2xl md:text-3xl font-bold text-black md:col-span-4 -mt-0.5 ">
              A simpler way to access discounts.
            </h2>
            <div className="col-span-1 md:col-span-8">
              <p className="mb-4 text-l text-neutral-600 md:text-xl text-[#86868B] text-left font-semibold w-4/5 md:ml-[8%]">
                With an unparalleled user interface, Rebite makes it easy to interact with
                local restaurants. With a host of food just a tap away, we open the door to
                discounts and deals from your favorite local eateries. Enjoy anything from pastries
                to pizzas, all fresh with deals being dispersed throughout the day!
              </p>
            </div>
          </div>
        </TextParallaxContent>
        <TextParallaxContent
          img={'https://firebasestorage.googleapis.com/v0/b/rebite-dev-f0771.appspot.com/o/Notif%20Render.png?alt=media&token=fc978214-e689-4fe2-aaee-167720dea511'}
          subheading=""
          heading="Local Eats Just A Notification Away">
          <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
            <h2 className="col-span-1 text-2xl md:text-3xl font-bold text-black md:col-span-4 -mt-0.5">
              Great food at your fingertips.
            </h2>
            <div className="col-span-1 md:col-span-8">
              <p className="mb-4 text-l text-neutral-600 md:text-xl text-[#86868B] text-left font-semibold w-4/5 md:ml-[8%]">
                Using location-based notifications, Rebite lets you know
                when food from your favorite restaurant is hosting a flash sale.
                Be sure to grab a meal before time runs out, because once it does you will need
                to wait for another flash-sale in order to use the app!
              </p>
            </div>
          </div>
        </TextParallaxContent>
      </div>

      {/* Footer Section */}
      <div className='h-[15vh] md:h-[20vh] bg-[#D9D9D9]'>
        <div className="grid grid-cols-3 gap-8 px-4 py-8 md:grid-cols-3 text-black max-w-6xl mx-auto">
          <div className="flex flex-col items-center md:items-start">
            <img src={Logo} alt="Logo" className="md:w-24 mb-2" />
            <p className="text-xs md:text-sm mb-1">contact@getrebite.com</p>
            <p className="text-xs md:text-sm">© 2024, Rebite Inc.</p>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <a href="https://apps.apple.com/us/app/rebite/id6618117899" className="text-sm md:text-base text-black mb-2">
              Download Rebite
            </a>
            <a href="/contact" className="text-sm md:text-base text-black">Support</a>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <a href="/terms" className="text-sm md:text-base text-black mb-2">Terms of Service</a>
            <a href="/privacy" className="text-sm md:text-base text-black">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};
